.ant-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-row-gap: 8px;
  row-gap: 8px;

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-pagination-item {
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-pagination-item-active {
      background-color: rgb(52, 151, 253);

      a {
        color: white;
      }
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}
